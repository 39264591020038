<template>
    <div class='container'>
        <div class="box1">
            <h3>你好，【{{ name }}】</h3>

            <div class="text">
                我们通过 遗传导致｜基因分析｜营养代谢 来科学的了解你的身体。
            </div>

            <div v-for="(items,index) in data.gene_products" :key="index">
                <div class="icon">
                    <div class="iconimg"><img src="https://khome2.tuzuu.com/vita/icon_7.png" alt=""></div>
                    <div>检测内容</div>
                </div>

                <table cellspacing="0" cellpadding="0">
                    <thead>
                    <tr>
                        <th>基因</th>
                        <th>基因名称</th>
                        <th>检测点位编号</th>
                        <th>结果</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ $$(data.gene_products, index).gene }}</td>
                        <td>{{ $$(data.gene_products, index).name }}</td>
                        <td>
                            <div class="flex-center" v-for="item in $$(data.gene_products,index).point_no">
                                {{ item }}
                            </div>
                        </td>
                        <td style="color: #FF0061">有风险</td>
                    </tr>
                    </tbody>
                </table>

                <div class="p" v-if="$$(data.gene_products,index).desc">
                    {{ $$(data.gene_products, index).desc }}
                </div>


                <div >
                    <div class="icon">
                        <div class="iconimg"><img src="https://khome2.tuzuu.com/vita/icon_8.png" alt=""></div>
                        <div>需求</div>
                    </div>

                    <van-image width="100%" :src="values.show_pic"

                               v-for="(values) in $$(data.gene_products,index).gene_products" :key="values.id"
                               @click="href(values.id)"/>

                </div>
            </div>

        </div>

        <div class="box2">
            <div class="hashagei">
                根据您的生活｜工作｜饮食的其他营养补充
            </div>

            <van-image width="100%" :src="values.show_pic" v-for="(values) in data.record_products" :key="values.id"
                       @click="href(values.id)"/>
        </div>


        <div class="boxgg">
            <p class="boxgg1">
                您的每日营养将根据营养需求分阶段进行调整 我们将尽快准备好并通过海外直邮发货<br>
                预计<span class="unmber7">7</span>个工作日到您手中
            </p>

            <div class="haha">
                如有任何问题请联系您的营养顾问
            </div>

        </div>

    </div>
</template>

<script>


    import {Toast} from "vant";

    export default {
        // Vue 实例的数据对象
        data() {
            return {
                data: {},
                name: ''
            }
        },
        created() {
            this.name = window.localStorage.getItem("name")
            this.getData()
        },

        methods: {
            getData() {
                let id = this.$route.params.id
                let toast = this.$toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    overlay:true
                });
                this.$request.get('reports', {
                    record_id: id
                }).then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        this.data = res.data
                        toast.clear()
                    } else {
                        Toast(res.message)
                    }

                })
            },
            href(id) {
                this.$router.push({
                    path: `/product/detail/${id}`
                })
            }
        }
    }
</script>

<style lang='less' scoped>
    .container {
        background: #f3f3f4;
        min-height: 100%;
        font-size: 14px;
        color: #3f3f3f;
        padding: 15px 10px;

        .box1 {
            padding: 20px 12px 1px 12px;
            background-color: #fefefe;
            border-radius: 10px;

            h3 {
                display: inline-block;
                font-size: 21px;
                font-weight: 700;
                padding-bottom: 6px;

                border-bottom: 3px solid #292c2f;
                margin-bottom: 25px;
            }

            .text {
                margin-bottom: 27px;
                line-height: 25px;
                padding-left: 8px;
                letter-spacing: 1px;
            }

            .icon {
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 26px;
                color: #232323;

                .iconimg {
                    width: 22px;
                    height: 22px;
                    margin-right: 6px;
                }
            }

            //表格
            table {
                border-collapse: separate;
                border-spacing: 0;
                width: 100%;
                font-size: 12px;

                th {
                    background: #9E3CF5;
                    padding-top: 10px;
                    padding-bottom: 8px;
                    color: white;
                    border-right: 1px solid #666666;

                    &:first-child {
                        border-top-left-radius: 5px;
                    }

                    &:last-child {
                        border-top-right-radius: 5px;
                        border-right: none;
                    }
                }

                td {
                    height: 80px;
                    border-bottom: 1px solid #666666;
                    border-right: 1px solid #666666;
                    text-align: center;
                    color: #666666;
                    font-weight: 500;

                    &:first-child {
                        border-left: 1px solid #666666;
                        border-bottom-left-radius: 5px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 5px;
                    }

                    div {
                        height: 50%;
                        box-sizing: border-box;

                        &:nth-of-type(2) {
                            border-top: 1px solid #666666;
                        }
                    }
                }
            }

            .p {
                margin-top: 12px;
                padding: 10px;
                background: #f7f7f7;
                border-radius: 5px;
                letter-spacing: 0.5px;
                line-height: 25px;
                font-size: 12px;
                box-sizing: border-box;
            }
        }

        .box2 {
            padding: 25px 10px;
            margin: 15px 0px;
            background: #fefefe;
            border-radius: 10px;

            .hashagei {
                text-align: center;
                font-size: 17px;
                margin-bottom: 20px;
                font-weight: 700;
                color: #232323;
            }

        }
        /deep/ .van-image {
            margin-bottom: 15px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .boxgg {
            font-size: 17px;
            color: #292c2f;

            .unmber7 {
                color: #fe4b12;
                display: inline-block;
                font-size: 24px;
                text-indent: 4px;
                letter-spacing: 4px;
            }

            .haha {
                padding-top: 10px;
                padding-bottom: 30px;
                font-size: 14px;

            }
        }
    }

    .boxgg1 {
        line-height: 30px;
        font-weight: 700;
    }

    img {
        width: 100%;
        height: 100%;
    }
</style>
